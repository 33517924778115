// Generated by Framer (89417f6)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./SnvTSu0C1-0.js";

const cycleOrder = ["dkx4nOFHG"];

const variantClassNames = {dkx4nOFHG: "framer-v-1lht8km"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dkx4nOFHG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wmvPY", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1lht8km", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dkx4nOFHG"} ref={ref} style={{...style}}><SVG className={"framer-t2re8n"} fill={"rgba(0,0,0,1)"} intrinsicHeight={51} intrinsicWidth={168} layoutDependency={layoutDependency} layoutId={"DtknJI10i"} svg={"<svg viewBox=\"0 0 42.138 12.692\"><g style=\"transform:scale(1,1)\"><path d=\"M2.518 0H39.62a2.518 2.518 0 0 1 2.518 2.518v7.656a2.518 2.518 0 0 1-2.518 2.518H2.518A2.518 2.518 0 0 1 0 10.174V2.518A2.518 2.518 0 0 1 2.518 0Z\" style=\"fill:#2c1e4a;opacity:1\"/></g></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wmvPY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wmvPY .framer-17vfb6m { display: block; }", ".framer-wmvPY .framer-1lht8km { height: 51px; overflow: hidden; position: relative; width: 168px; }", ".framer-wmvPY .framer-t2re8n { aspect-ratio: 3.2941176470588234 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 51px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 168
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerSnvTSu0C1: React.ComponentType<Props> = withCSS(Component, css, "framer-wmvPY") as typeof Component;
export default FramerSnvTSu0C1;

FramerSnvTSu0C1.displayName = "cena 2 c";

FramerSnvTSu0C1.defaultProps = {height: 51, width: 168};

addFonts(FramerSnvTSu0C1, [])